<template>
  <div>
      <ed-modelo :intTipoModeloId="1321" :key="$root.$session.versao" :intId="0"/>
  </div>
</template>

<script>
import EdModelo from "@/components/documento/modelo/editar";
export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdModelo },
  mounted() {
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>